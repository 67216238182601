import React from 'react';
import Container from 'react-bootstrap/Container';
import dynamic from "next/dynamic";
import {useTranslation} from 'next-i18next';
import defaultImg from '../../../public/upyo-default.png'

// styles
import styles from "./Resources.module.scss";
import Image from "next/legacy/image";
import Link from 'next/link';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {convertUtc} from "../../../helper/utilityHelper";
import {useRouter} from "next/router";

const CustomButton = dynamic(() => import('../../common/CustomButton'))

const Resource = (props) => {
    const curLang = props.currLocale
    const { t } = useTranslation('common');
    const router = useRouter();

    const readingTime = (text) => {
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        return Math.ceil(words / wpm)
    }

    return (
        <section className={`section ${styles.resorceMain}`}>
            <Container>
                <div className={styles.resource__wrapper}>
                    <div className={`d-flex align-items-center justify-content-between ${styles.mainTitle}`}>
                        <h2 className={`h3 mb-0 ${styles.h3}`}>{t('common:resource_title')}</h2>
                        <CustomButton href={`/blog`} name={t('common:view_all')} btnClassName="buttonStroke d-none d-sm-inline-flex" />
                    </div>
                    <div className={styles.cardWrap}>
                        <Swiper
                            dir={curLang === "ar" ? "rtl" : "ltr"}
                            navigation={{
                                nextEl: '.swiper1-button-next',
                                prevEl: '.swiper1-button-prev',
                            }}
                            modules={[Navigation]}
                            className="p-0 direCtions"
                            freeMode={true}
                            mousewheel={true}
                            grabCursor={true}
                            speed={400}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                    slidesPerGroup: 1
                                },
                                576: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2
                                },
                                1200: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 3
                                },
                            }}
                        >
                            {props.resources?.map(list => {
                                return (
                                    <SwiperSlide key={list.slug}>
                                        <div className={styles.resourceList}>
                                            <Link href={`/post/${list.slug}`}
                                                  className={styles.card}>
                                                <div className={styles.cardImg}>
                                                        <Image src={list.feature_image ? list.feature_image : defaultImg}
                                                               width={410} height={215}
                                                               alt="resourceImg"
                                                               layout="responsive" />
                                                    </div>
                                                <div className={`${styles.cardBody} ${styles.topicPrmain}`}>
                                                        {list.articleCategory?.length > 0 ?
                                                            <span className={styles.topicTag}>
                                                                {list.articleCategory.map((info) => (
                                                                        <button key={info.name} className={styles.tag}>
                                                                            {info.name}
                                                                        </button>
                                                                    )
                                                                )}
                                                            </span> :
                                                            null}
                                                        {/* <div className={styles.name}>{list.title}</div> */}
                                                        <div className={styles.topicProps}>
                                                            <div className={styles.title}>{list.title}</div>
                                                            <div className={styles.descriptions}>
                                                                <span className={styles.avtarImg}>
                                                                    <div className={styles.avatar}>
                                                                        <Image src={list.author?.profileImage ? list.author.profileImage : defaultImg}
                                                                            width={40} height={40}
                                                                            alt={list.author?.name ? list.author.name : 'profile'} />
                                                                    </div>
                                                                </span>
                                                                <div className={styles.avtarRight}>
                                                                    <span className={styles.authName}>
                                                                        By <input type="button"
                                                                            onClick={(event) => {
                                                                                event.preventDefault();
                                                                                router.push(`${process.env.hostBaseUrl}/${props.currLocale}/blog/author/${list.author && list.author.slug ? list.author.slug : 'upyo-team'}`)
                                                                            }}
                                                                            value={list.author?.name ? list.author.name : `UPYO Team`} />
                                                                    </span>
                                                                    <div className={`d-flex flex-wrap ${styles.dateInfo}`}>
                                                                        <span>{convertUtc(list.date)}</span>
                                                                        <span>{list.content ? readingTime(list.content) : ''} {t('mins_read')}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                        <div className="swiper1-button-next"></div>
                        <div className="swiper1-button-prev"></div>
                    </div>
                    <div className={`text-center d-block d-sm-none ${styles.mobbtn}`}>
                        <CustomButton href={`/blog`}
                                      name={t('common:view_all')}
                                      btnClassName={`buttonStroke loadmoreBtn ${styles.viewAll}`} />
                    </div>
                </div>
            </Container>
        </section>

    )

}
export default Resource;
